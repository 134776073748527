import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

// Models
import { IUser, User } from "../../models/user";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(private readonly _jwtHelperService: JwtHelperService) {}

  public getToken(): string | null {
    return localStorage.getItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nS2V5");
  }

  public setToken(token: string): void {
    localStorage.setItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nS2V5", token);
  }

  public getUser(): IUser {
    let user: IUser = {
      commissionId: 1,
      commissionLevel: "1",
      commissionName: "Nacional",
    };
    return user;
  }

  public setUser(user: User | null): void {
    let _user = JSON.stringify(user);
    localStorage.setItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nRGF0YQ==", _user);
  }
 
  public logout = (): any => localStorage.clear();
}
