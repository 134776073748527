import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
  providedIn: "root",
})
export class ResponsiveService {
  private readonly stateResponsive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isSmallView: boolean = false;

  /****************** Functions Emitters ********************************/

  public getSubjectResponsive(): Subject<boolean> {
    return this.stateResponsive$;
  }

  public emitterActionResponsive(state: boolean): void {
    this.isSmallView = state;
    this.stateResponsive$.next(state);
  }
}
