export enum Url {
  fusion,
  menu,
  home,
  mock,
  default,
}

export enum TypeData {
  num,
  text,
  date,
  progress,
  button,
  textWithouthHover,
  hash,
  viewDocument,
  desc,
  textWithHash,
}

export enum TablesStyle {
  line,
  tab,
}

export enum TypeFilter {
  origin = "origin",
  corporation = "corporation",
  departament = "departament",
  town = "town",
  commission = "commission",
  subcommission = "subcommission",
  zone = "zone",
  point = "point",
  table = "table",
  date = "date",
}

export enum Level {
  country = "País",
  departament = "Departamento",
  town = "Municipio",
  commission = "Comisión",
  zone = "Zona",
  point = "Puesto",
  table = "Mesa",
}

export enum TypeNovedades {
  extemporaneos = 1,
  malEstado = 2,
  conTachaduras = 3,
  conVotosIncinerados = 4,
  excluidas = 5,
  menos2Firmas = 6,
  modificadas = 7,
  recontadaComision = 8,
  recontadaJurados = 9,
  sinVotacion = 10,
}

export enum TypeDocument {
  age = "AGE",
  e24 = "E24",
  e26 = "E26",
  mmv = "MMV",
  mms = "MMS",
  vot = "VOT",
  ace = "ACE",
  lis = "LIS",
}

export enum CaseTypeDocument {
  download,
  main,
  read,
}

export const getLevelWithIndex = (index: number): Level => {
  switch (index) {
    case 1:
      return Level.country;
    case 2:
      return Level.departament;
    case 3:
      return Level.town;
    default:
      return Level.country;
  }
};

export const getNextLevel = (level: Level): Level => {
  switch (level) {
    case Level.country:
      return Level.departament;
    case Level.departament:
      return Level.town;
    case Level.town:
      return Level.zone;
    case Level.zone:
      return Level.point;
    case Level.point:
      return Level.table;
    default:
      return Level.country;
  }
};

export const getTypeFilterOfLevel = (level: Level): TypeFilter => {
  switch (level) {
    case Level.country:
      return TypeFilter.departament;
    case Level.departament:
      return TypeFilter.town;
    case Level.town:
      return TypeFilter.commission;
    case Level.commission:
      return TypeFilter.zone;
    case Level.zone:
      return TypeFilter.point;
    case Level.point:
      return TypeFilter.table;
    default:
      return TypeFilter.departament;
  }
};

export const getTypeFilterWithIndex = (index: number): TypeFilter => {
  switch (index) {
    case 1:
      return TypeFilter.departament;
    case 2:
      return TypeFilter.departament;
    case 3:
      return TypeFilter.town;
    default:
      return TypeFilter.departament;
  }
};

export enum DateFormat {
  monthText,
  default,
}

export enum TypeNav {
  nacional = "Nacional",
  nan = "NaN",
}
